/**
 * ns.wow Theme
 * Facade class for public accessible core functionality.
 */

class Theme {

  static version() {
    return NSContext.theme.version;
  }

}

export default Theme;
